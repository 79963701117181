// Ascension Brand Colors
// https://www.figma.com/file/FDaCA02JPjEqvKuCU8dgtJ/DS-Foundations-1.0.0---WIP?node-id=0%3A1

const primary = {
  50: '#E8F5FE',
  100: '#D1EAFC',
  200: '#A4D3FA',
  300: '#75B3F1',
  400: '#5194E3',
  500: '#1E69D2',
  600: '#1551B4',
  700: '#0F3C97',
  800: '#092A79',
  900: '#051D64',
}

const secondary = {
  50: '#FDE6EE',
  100: '#FBCDDC',
  200: '#F79CC4',
  300: '#E868AC',
  400: '#D2419C',
  500: '#B40F87',
  600: '#9A0A81',
  700: '#810778',
  800: '#660468',
  900: '#4C0256',
}

const grey = {
  50: '#FAFAFA',
  100: '#F5F5F5',
  200: '#EEEEEE',
  300: '#E0E0E0',
  400: '#BDBDBD',
  500: '#9E9E9E',
  600: '#757575',
  700: '#616161',
  800: '#424242',
  900: '#212121',
}

const danger = {
  50: '#FEEBE4',
  100: '#FDD7CA',
  200: '#FBA797',
  300: '#F36B62',
  400: '#E83B40',
  500: '#D9021C',
  600: '#BA0128',
  700: '#9C0130',
  800: '#7D0032',
  900: '#680033',
}

const warning = {
  50: '#FFF7E6',
  100: '#FFEFCC',
  200: '#FFDB99',
  300: '#FFC166',
  400: '#FFA83F',
  500: '#FF7F00',
  600: '#DB6200',
  700: '#B74900',
  800: '#933300',
  900: '#7A2400',
}

const success = {
  50: '#E9FBE7',
  100: '#D4F8D0',
  200: '#A4F2A4',
  300: '#70D87B',
  400: '#46B25C',
  500: '#198038',
  600: '#126E36',
  700: '#0C5C34',
  800: '#074A2F',
  900: '#043D2C',
}

const brand = {
  primary: {
    500: '#1E69D2',
    700: '#0F3C97',
  },
  secondary: {
    500: '#B40F87',
    700: '#810778',
  },
  success: {
    500: '#126E36',
  },
  warning: {
    500: '#FF7F00',
    700: '#B74900',
  },
  error: {
    300: '#EE6070',
    500: '#C8023E',
    700: '#900148',
  },
  gold: {
    300: '#FFD266',
    400: '#FFC333',
    500: '#FFB400',
  },
  green: {
    300: '#00A791',
    400: '#33B9A7',
    500: '#00A791',
  },
  gradient: {
    blue: {
      0: '#1B4297',
      100: '#1E69D2',
    },
  },
}

export const colors = {
  primary,
  secondary,
  grey,
  danger,
  warning,
  success,
  brand,
}

export { primary, secondary, grey, danger, warning, success, brand }
