// Oldest browsers according to Ascension Google Analytics:
// IE >= 9, Safari >= 8, Chrome >= 28.x

// https://material-ui.com/guides/typescript/
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeOptions,
} from '@material-ui/core/styles'
import { colors } from './colors'

// custom themes props:
// https://material-ui.com/guides/typescript/#customization-of-theme
// https://material-ui.com/customization/theming/#custom-variables

export const ascensionTheme: ThemeOptions = {
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  // direction: 'ltr',
  // mixins: {},
  props: {
    // Default props given to components
    MuiButton: {
      size: 'large',
    },
  },
  overrides: {
    // CssBaseline is same as Normalize
    MuiCssBaseline: {
      '@global': {
        'html': {
          // fixes iOS text size issues.
          WebkitTextSizeAdjust: '100%',
        },
        // 'input[type="number"]': {
        //   '&::-webkit-inner-spin-button': {
        //     appearance: 'none',
        //   },
        // },
        // how to change base component styles via selectors:
        '.MuiButton-root': {
          // example: ...
        },
      },
    },
    // change styles via API css props
    MuiButton: {
      outlined: {
        border: `1px solid rgba(0,0,0,0.5)`,
      },
      contained: {
        '&:disabled': {
          color: 'rgba(255,255,255,0.5)',
        },
      },
      outlinedPrimary: {
        border: `1px solid ${colors.brand.primary[500]}`,
      },
      outlinedSecondary: {
        border: `1px solid ${colors.brand.secondary[500]}`,
      },
      sizeLarge: {
        fontSize: '0.875rem',
      },
      textSizeLarge: {
        padding: '12px 11px',
      },
      outlinedSizeLarge: {
        padding: '11px 21px',
      },
      containedSizeLarge: {
        padding: '12px 22px',
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: '14.5px 14px',
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 17px) scale(1)',
      },
    },
    MuiFormHelperText: {
      root: {
        marginTop: '4px',
      },
    },
  },

  palette: {
    primary: {
      main: colors.brand.primary[500],
      dark: colors.brand.primary[700],
    },
    secondary: {
      main: colors.brand.secondary[500],
      dark: colors.brand.secondary[700],
    },
    error: {
      main: colors.brand.error[500],
      dark: colors.brand.error[700],
    },
    warning: {
      main: colors.brand.warning[500],
    },
    success: {
      main: colors.brand.success[500],
    },
    grey: colors.grey,
    action: {
      disabled: 'rgba(0,0,0,0.5)',
      disabledBackground: 'rgba(0,0,0,0.5)',
    },
  },
  typography: {
    fontFamily: [
      'HCo Whitney SSm',
      '-apple-system',
      'BlinkMacSystemFont',
      'Roboto',
      'San Francisco',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 600,
    h1: {},
    h2: {},
    h3: { fontWeight: 400 },
    h4: {},
    h5: {},
    h6: {},
    subtitle1: {},
    subtitle2: {},
    body1: {},
    body2: {},
    button: {},
    caption: {},
    overline: {},
  },
  shadows: [
    `none`,
    `0px 2px 1px -1px rgba(0,0,0,0.1),
     0px 1px 1px 0px rgba(0,0,0,0.07),
     0px 1px 3px 0px rgba(0,0,0,0.06)`,
    `0px 3px 1px -2px rgba(0,0,0,0.1),
     0px 2px 2px 0px rgba(0,0,0,0.07),
     0px 1px 5px 0px rgba(0,0,0,0.06)`,
    `0px 3px 3px -2px rgba(0,0,0,0.1),
     0px 3px 4px 0px rgba(0,0,0,0.07),
     0px 1px 8px 0px rgba(0,0,0,0.06)`,
    `0px 2px 4px -1px rgba(0,0,0,0.1),
     0px 4px 5px 0px rgba(0,0,0,0.07),
     0px 1px 10px 0px rgba(0,0,0,0.06)`,
    `0px 3px 5px -1px rgba(0,0,0,0.1),
     0px 5px 8px 0px rgba(0,0,0,0.07),
     0px 1px 14px 0px rgba(0,0,0,0.06)`,
    `0px 3px 5px -1px rgba(0,0,0,0.1),
     0px 6px 10px 0px rgba(0,0,0,0.07),
     0px 1px 18px 0px rgba(0,0,0,0.06)`,
    `0px 4px 5px -2px rgba(0,0,0,0.1),
     0px 7px 10px 1px rgba(0,0,0,0.07),
     0px 2px 16px 1px rgba(0,0,0,0.06)`,
    `0px 5px 5px -3px rgba(0,0,0,0.1),
     0px 8px 10px 1px rgba(0,0,0,0.07),
     0px 3px 14px 2px rgba(0,0,0,0.06)`,
    `0px 5px 6px -3px rgba(0,0,0,0.1),
     0px 9px 12px 1px rgba(0,0,0,0.07),
     0px 3px 16px 2px rgba(0,0,0,0.06)`,
    `0px 6px 6px -3px rgba(0,0,0,0.1),
     0px 10px 14px 1px…gba(0,0,0,0.07),
     0px 4px 18px 3px rgba(0,0,0,0.06)`,
    `0px 6px 7px -4px rgba(0,0,0,0.1),
     0px 11px 15px 1px…gba(0,0,0,0.07),
     0px 4px 20px 3px rgba(0,0,0,0.06)`,
    `0px 7px 8px -4px rgba(0,0,0,0.1),
     0px 12px 17px 2px…gba(0,0,0,0.07),
     0px 5px 22px 4px rgba(0,0,0,0.06)`,
    `0px 7px 8px -4px rgba(0,0,0,0.1),
     0px 13px 19px 2px…gba(0,0,0,0.07),
     0px 5px 24px 4px rgba(0,0,0,0.06)`,
    `0px 7px 9px -4px rgba(0,0,0,0.1),
     0px 14px 21px 2px…gba(0,0,0,0.07),
     0px 5px 26px 4px rgba(0,0,0,0.06)`,
    `0px 8px 9px -5px rgba(0,0,0,0.1),
     0px 15px 22px 2px…gba(0,0,0,0.07),
     0px 6px 28px 5px rgba(0,0,0,0.06)`,
    `0px 8px 10px -5px rgba(0,0,0,0.1),
     0px 16px 24px 2p…gba(0,0,0,0.07),
     0px 6px 30px 5px rgba(0,0,0,0.06)`,
    `0px 8px 11px -5px rgba(0,0,0,0.1),
     0px 17px 26px 2p…gba(0,0,0,0.07),
     0px 6px 32px 5px rgba(0,0,0,0.06)`,
    `0px 9px 11px -5px rgba(0,0,0,0.1),
     0px 18px 28px 2p…gba(0,0,0,0.07),
     0px 7px 34px 6px rgba(0,0,0,0.06)`,
    `0px 9px 12px -6px rgba(0,0,0,0.1),
     0px 19px 29px 2p…gba(0,0,0,0.07),
     0px 7px 36px 6px rgba(0,0,0,0.06)`,
    `0px 10px 13px -6px rgba(0,0,0,0.1),
     0px 20px 31px 3…gba(0,0,0,0.07),
     0px 8px 38px 7px rgba(0,0,0,0.06)`,
    `0px 10px 13px -6px rgba(0,0,0,0.1),
     0px 21px 33px 3…gba(0,0,0,0.07),
     0px 8px 40px 7px rgba(0,0,0,0.06)`,
    `0px 10px 14px -6px rgba(0,0,0,0.1),
     0px 22px 35px 3…gba(0,0,0,0.07),
     0px 8px 42px 7px rgba(0,0,0,0.06)`,
    `0px 11px 14px -7px rgba(0,0,0,0.1),
     0px 23px 36px 3…gba(0,0,0,0.07),
     0px 9px 44px 8px rgba(0,0,0,0.06)`,
    `0px 11px 15px -7px rgba(0,0,0,0.1),
     0px 24px 38px 3…gba(0,0,0,0.07),
     0px 9px 46px 8px rgba(0,0,0,0.06)`,
  ],
}

export const theme = createMuiTheme(ascensionTheme)

export const responsiveTheme = responsiveFontSizes(theme)
